@import url("https://fonts.googleapis.com/css?family=Lato:400,900|IBM+Plex+Sans:400,700");

#sidebarA{
    color: white;
}

#filterResultsContainer{
    height: 95%;
}

#filterResults{
    font-size: 12px;
    color: white;
    background-color: #448EE4;

}

.theme{
    background-color: #2A395F;
}

.select{
    font-style: italic;
    opacity: .7;
}

.mapboxgl-ctrl-geocoder--input {
    padding: 6px 30px;
    margin-left: 5px;
}

.listings .item {
    font-size: 14px;
    word-wrap: break-word;
    text-decoration: none;
    border-bottom: solid rgb(34, 33, 33, .3) 1px;
}

.listings .item:last-child {
    border-bottom: none;
}

.listings .item .title {
    display: inline-block;
    width: 100%;
    color: #2A395F;
    font-weight: 800;
    margin-top: 5px;
    text-decoration: none;
}


::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-left: 0;
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
    background: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #2A395F;
}


.clearfix {
    display: block;
}

.clearfix::after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.mapboxgl-popup-content {
    font: 400 15px/22px
    padding: 5;
  }

.mapboxgl-popup-close-button {
    display: none;
  }

.mapboxgl-popup-content h3 {
    color:black;
    font-weight: 500;
    margin: 0;
    display: block;
  }

  .marker {
    background-image: url('mapbox-icon.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }
  
/* css for newUI */
body {
    margin: 0;
    padding: 0;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}
    .rounded-rect {
        background: white;
        border-radius: 10px;
        box-shadow: 0 0 50px -25px black;
    }

    .flex-center {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .flex-center.left {
        left: 0px;
    }

    .flex-center.right {
        right: 0px;
    }

    .sidebar-content {
        position: absolute;
        width: 95%;
        height: 95%;
        font-size: 32px;
        color: gray;
    }

    .sidebar-toggle {
        position: absolute;
        width: 1.3em;
        height: 1.3em;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  #leftSidebarToggle{
    top: 50%;
    left: 110%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);

  }
    .sidebar-toggle.left {
        right: -1.5em;
        float: right;
    }

    .sidebar-toggle.right {
        left: -1.5em;
    }

    .sidebar-toggle:hover {
        color: #0aa1cf;
        cursor: pointer;
    }

    .sidebar {
        transition: transform 1s;
        z-index: 1;
        width: 300px;
        height: 100%;
    }

    /*
  The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
  The toggleSidebar() function removes this class from the element in order to expand it.
*/
    .left.collapsed {
        transform: translateX(-295px);
    }

    .right.collapsed {
        transform: translateX(295px);
    }
